import { Component, Mixins } from "vue-property-decorator";

import CashFlowMixin from "../../common/mixins/cash-flow.mixin";

@Component
export default class DesktopCashFlow extends Mixins(CashFlowMixin) {
  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();
  }
}
